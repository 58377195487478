@import "@/assets/scss/var.scss";
.menu {
    background: #f7f8fa;
    // overflow: auto;
    // flex: 1;
    .page-content {
        // margin: 0 auto;
        // background: #f7f8fa;
        // font-size: 1.25em;
        // margin-bottom: 20px;
        // width: $com-width;
        // min-width: 960px;
        // margin: 0 auto;
        // font-size: 16px;
        // padding-top: 10px;
        // margin-top: 10px;
        display: flex;
        .sidemenu {
            background: #fff;
            width: 220px;

            .title {
                padding: 30px 30px 10px 30px;
                font-weight: bold;
            }
            .el-menu {
                padding: 10px;
                border-right: none;
            }
        }

        .section {
            flex: 1;
            background: #fff;
            padding: 30px 30px;
            margin-left: 25px;
            /deep/ .pg-title {
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
}
